<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(submit)" class="custom-scrollbar" id="company-profile">
          <loader :loader="loader" />
          <div class="company-data">
            <md-card class="card-content" :key="renderKey" id="md-card">
              <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
                <div class="card-icon">
                  <md-icon>factory</md-icon>
                </div>
                <h4 class="title">
                  {{ $t('customerProfileTitle') }}
                </h4>
                <!-- <h3>{{ $t("profileText") }}</h3> -->
              </md-card-header>

              <md-card-content>
                <div class="md-layout scroll-behavior custom-pt">
                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('customerID') }}</label>
                      <md-input v-model="company.ID" type="text" disabled></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('vatNr') }}</label>
                      <md-input @change="toggleSave" v-model="company.VATnr" type="text"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="customerNo" rules="required|numeric" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label>{{ $t('customerNumber') }}</label>
                          <md-input @change="toggleSave" v-model="company.CustomerNr" type="text"></md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="companyName" rules="required" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label>{{ $t('companyName') }}</label>
                          <md-input @change="toggleSave" v-model="company.Name" type="text"></md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label for="companyType">{{ $t('companyType') }}</label>
                      <md-select
                        @input="toggleSave"
                        v-model="company.CompanyTypeID"
                        name="companyType"
                        id="companyType"
                      >
                        <md-option v-for="cType in companyTypesData" :value="cType.ID" :key="cType.ID">{{
                          cType.Name
                        }}</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="companyStreet" rules="required" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label>{{ $t('street') }}</label>
                          <md-input @change="toggleSave" v-model="company.Street" type="text"></md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="companyHsNo" rules="required|numeric" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label>{{ $t('houseNumber') }}</label>
                          <md-input @change="toggleSave" v-model="company.Housenumber" type="text"></md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="zipcode" rules="required|numeric" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label> {{ $t('zipCode') }} </label>
                          <md-input @change="toggleSave" v-model="company.ZipCode" type="text"> </md-input>

                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <div>
                      <ValidationProvider name="companyCity" rules="required" v-slot="{ passed, failed }">
                        <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                          <label>{{ $t('city') }}</label>
                          <md-input @change="toggleSave" v-model="company.City" type="text"></md-input>
                          <slide-y-down-transition>
                            <md-icon class="error" v-show="failed">close</md-icon>
                          </slide-y-down-transition>
                          <slide-y-down-transition>
                            <md-icon class="success" v-show="passed">done</md-icon>
                          </slide-y-down-transition>
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <select-country
                      :value="company.Language.toUpperCase()"
                      @input="toggleSave"
                      @changeCountry="handleCountry"
                    ></select-country>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <ValidationProvider name="email" rules="email" v-slot="{ passed, failed }">
                      <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                        <label>{{ $t('email') }}</label>
                        <md-input @change="toggleSave" v-model="company.Email" type="email"> </md-input>

                        <slide-y-down-transition>
                          <md-icon class="error" v-show="failed">close</md-icon>
                        </slide-y-down-transition>
                        <slide-y-down-transition>
                          <md-icon class="success" v-show="passed">done</md-icon>
                        </slide-y-down-transition>
                      </md-field>
                    </ValidationProvider>
                  </div>
                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('phoneNumber') }}</label>
                      <md-input @change="toggleSave" v-model="company.Phone" type="text"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('faxNumber') }}</label>
                      <md-input @change="toggleSave" v-model="company.Fax" type="text"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('website') }}</label>
                      <md-input @change="toggleSave" v-model="company.Website" type="text"></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-33">
                    <md-field>
                      <label>{{ $t('serviceContract') }}</label>
                      <md-select
                        @input="toggleSave"
                        v-model="company.ServiceContract"
                        name="serviceContract"
                        id="serviceContract"
                      >
                        <md-option value="true">{{ $t('yes') }}</md-option>
                        <md-option value="false">{{ $t('no') }}</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-33">
                    <md-field>
                      <label>{{ $t('serviceContractStart') }}</label>
                      <md-input @change="toggleSave" type="date" v-model="company.ServiceContractStart" />
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-33">
                    <md-field>
                      <label>{{ $t('serviceContractEnd') }}</label>
                      <md-input @change="toggleSave" type="date" v-model="company.ServiceContractEnd" />
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('createdBy') }}</label>
                      <md-input @change="toggleSave" v-model="company.CreatedBy" type="text" disabled></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('createdOn') }}</label>
                      <md-input @change="toggleSave" v-model="company.Created" type="text" disabled></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('modifiedBy') }}</label>
                      <md-input @change="toggleSave" v-model="company.ModifiedBy" type="text" disabled></md-input>
                    </md-field>
                  </div>

                  <div class="md-layout-item md-small-size-100 md-size-50">
                    <md-field>
                      <label>{{ $t('modifiedOn') }}</label>
                      <md-input @change="toggleSave" v-model="company.Modified" type="text" disabled></md-input>
                    </md-field>
                  </div>
                  <div
                    v-if="allow('{60d57c5c-bd17-405c-9e1e-290038937b07}')"
                    class="md-layout-item md-size-50 text-bottom"
                    :class="{ entered: dragging }"
                    @drop.prevent="onFileChange"
                    @dragover.prevent="handleDragOver"
                    @dragleave="handleDragLeave"
                  >
                    <md-button class="md-round md-primary md-fileinput btn-custom">
                      <template
                        ><span>{{ $t('loadLicFile') }}</span></template
                      >
                      <input accept=".lic" type="file" @change="onFileChange" />
                    </md-button>
                  </div>
                  <div v-else class="md-layout-item md-size-50 text-bottom"></div>
                  <div class="md-layout-item md-size-50 text-right">
                    <md-button
                      id="submit"
                      class="md-raised md-primary mt-4 md-round"
                      @click="updateCompany()"
                      :disabled="invalid || !enableSave"
                      >{{ $t('save') }}
                    </md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="spacer"></div>
    <!--:companyTypes="this.companyTypes"-->
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <customer-contacts :companyID="this.company.ID"></customer-contacts>
    </div>
    <div class="md-layout-item md-size-100">
      <products-list></products-list>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useCompanyStore } from '../../stores/company'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import CustomerContacts from '../CompanyContacts/CompanyContactsList.vue'
import ProductsList from '../Products/ProductsComponent/ProductsList.vue'
import { permissionMixin } from '../RoleManagement/permissionMixin'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

let cObj

export default {
  mixins: [permissionMixin],
  components: { SelectCountry, ValidationProvider, CustomerContacts, ProductsList },
  name: 'edit-company-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    return { companyStore, userStore }
  },
  data() {
    return {
      company: {
        ID: '',
        CompanyTypeID: '',
        CustomerNr: '',
        VATnr: '',
        Name: '',
        Street: '',
        Housenumber: '',
        ZipCode: '',
        City: '',
        Country: '',
        Phone: '',
        Fax: '',
        Email: '',
        Website: '',
        Language: '',
        Image: 0,
        ServiceContract: false,
        ServiceContractStart: '',
        ServiceContractEnd: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
      },
      dragging: false,
      renderKey: 0,
      companyTypesData: [
        {
          ID: '{32456e70-c313-4ca5-ac48-e4980fff092f}',
          Name: this.$t('vocationalSchool'),
          key: 'vocationalSchool',
        },
        {
          ID: '{4e3e30e5-582e-4392-9d6a-8e3510518c08}',
          Name: this.$t('company'),
          key: 'company',
        },
        {
          ID: '{5e0c53b2-f4bd-45e4-8993-418a267d7091}',
          Name: this.$t('university'),
          key: 'university',
        },
        {
          ID: '{9a4d6dd7-ea25-4ebf-8dc5-dd44fb9b1262}',
          Name: this.$t('college'),
          key: 'college',
        },
        {
          ID: '{cd0b2330-4476-4af0-b3c1-b12b4598967f}',
          Name: this.$t('groupOfCompanies'),
          key: 'groupOfCompanies',
        },
      ],
      allProducts: [
        {
          ID: '',
          ProductIDExtern: 0,
          Code: '',
          Name: '',
          Title: '',
          Subtitle: '',
          Teaser: '',
          Link: '',
          Image: 0,
          TitleEN: '',
          SubtitleEN: '',
          TeaserEN: '',
          LinkEN: '',
          ImageEN: 0,
        },
      ],
      selectedProductIDs: [],
      selectedProducts: [
        {
          ID: '',
          ProductIDExtern: 0,
          Code: '',
          Name: '',
          Title: '',
          Subtitle: '',
          Teaser: '',
          Link: '',
          Image: 0,
          TitleEN: '',
          SubtitleEN: '',
          TeaserEN: '',
          LinkEN: '',
          ImageEN: 0,
        },
      ],
      loader: false,
      tabcounter: 0,
      enableSave: false,
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateCompanyTypesData()
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.updateCompanyTypesData()
    this.company = this.companyStore.company
    this.companyStore.companyContact = {}
    if (this.companyTypesData && this.company.ID !== '') {
      this.company.ServiceContractStart = this.company.ServiceContractStart.split('T')[0]
      this.company.ServiceContractEnd = this.company.ServiceContractEnd.split('T')[0]
    } else {
      this.$router.push({ name: 'Companies' })
    }
  },
  mounted() {
    this.$changePageTitle(this.$t('pageTitleEditCompanyProfile'))
  },
  methods: {
    updateCompanyTypesData() {
      this.companyTypesData.forEach((type) => {
        type.Name = this.$t(type.key)
      })
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      if (val.value.toLowerCase() !== this.company.Language) this.enableSave = true
      this.company.Language = val.value.toLowerCase()
      this.company.Country = val.label
    },
    async updateCompany() {
      this.company.ModifiedBy = this.userStore.userData.LastName
      this.company.Modified = this.companyStore.formatDate(new Date())
      this.loader = true
      const statusUpdate = await this.companyStore.updateCompanyData(this.company)
      this.loader = false
      this.enableSave = false
      if (statusUpdate === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('profileUpdated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: statusUpdate.data.Message,
          icon: 'error',
          confirmButtonText: this.$t('ok'),
        })
      }
    },
    manageProducts() {
      this.$router.push({
        name: 'Company Products',
        // params: { companyID: this.company.ID },
      })
    },
    toggleSave() {
      this.enableSave = true
    },
    onFileChange(e) {
      this.dragging = false

      Swal.fire({
        icon: 'info',
        title: 'Noch keine Funktion',
      })
      return

      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const file = files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {}
    },
    handleDragOver(event) {
      event.preventDefault()
      this.dragging = true
    },
    handleDragLeave() {
      this.dragging = false
    },
  },
}
</script>

<style scoped lang="scss">
.md-card {
  width: 100%;
  margin: 0 auto;
}

.spacer {
  height: 2rem;
}
.center-located {
  margin: 0 auto;
}
.products-list-content {
  .company-name-title {
    padding-left: 10rem !important;
  }
}
.company-data {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.custom-pt {
  padding-top: 6px;
}
</style>
