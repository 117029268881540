<template>
  <div @mouseover="showSelectIconFunc()" @mouseout="hideSelectIconFunc()">
    <md-field id="lang" :class="{ 'change-icon-style': showSelectIcon }">
      <md-select v-model="$i18n.locale" name="lang">
        <md-option v-for="lang in langs" :key="$t(lang)" :value="lang" id="lang">
          {{ $t(lang) }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return { langs: ['de-DE', 'en-US'], showSelectIcon: false }
  },
  methods: {
    addCustomClass() {
      // Use setTimeout to ensure that the class is added after the menu is rendered
      setTimeout(() => {
        const selectMenu = document.querySelector('.md-select-menu')
        if (selectMenu) {
          selectMenu.classList.add('lang-select-menu')
        }
      }, 0)
    },
    showSelectIconFunc() {
      this.showSelectIcon = true
    },
    hideSelectIconFunc() {
      this.showSelectIcon = false
    },
  },
  beforeMount() {
    this.$i18n.locale = localStorage.getItem('locale') || 'de-DE'
    if (window.location.href.includes('lang=en')) {
      this.$i18n.locale = 'en-US'
    }
    if (window.location.href.includes('lang=de')) {
      this.$i18n.locale = 'de-DE'
    }
    localStorage.setItem('locale', this.$i18n.locale)
  },
  mounted() {
    if (window.location.pathname === "/login" || window.location.pathname === "/registration") {
      window.history.replaceState(null, '', window.location.origin + window.location.pathname)
    }
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      localStorage.setItem('locale', newLocale)
    },
  },
}
</script>

<style scoped>
#lang {
  width: 110px;
  height: 100%;
}

/deep/ .md-icon.md-theme-default.md-icon-image svg {
  fill: #3c4858 !important;
}

/deep/ #lang.change-icon-style>div>i>svg {
  display: block;
}

/deep/ #lang>div>i>svg {
  display: none;
}

/deep/ .md-menu.md-select>input {
  -webkit-text-fill-color: #3c4858;
}

/* /deep/ .md-icon.md-icon-font.md-icon-image.md-theme-default {
  margin-right: 0 !important;
  margin-bottom: 3px !important;
} */
.md-field.md-theme-default:after {
  display: none;
}

.md-field.md-theme-default:before {
  display: none;
}

@media (max-width: 991px) {
  .nav-mobile-menu>#lang {
    width: 210px;
  }
}
</style>
