<template>
  <div class="md-layout scroll-behavior">
    <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
      <loader :loader="loader" />
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>list</md-icon>
          </div>
          <div class="md-title">{{ $t('awsAccesses') }}</div>
          <div class="md-subtitle">{{ this.companyStore.company.Name }}</div>
        </md-card-header>
        <md-card-content>
          <md-table v-if="awsAccounts.length > 0" v-model="awsAccounts">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-sort-by="id" :md-label="$t('name')">
                <strong>{{ item.FleetReadable }}</strong>
              </md-table-cell>

              <md-table-cell md-sort-by="id" :md-label="$t('startDate')">
                {{ getReadableDate(item.StartDate) }}
              </md-table-cell>

              <md-table-cell md-sort-by="id" :md-label="$t('endDate')">
                {{ getReadableDate(item.EndDate) }}
              </md-table-cell>

              <md-table-cell :md-label="$t('accessActive')">
                <md-icon v-if="getActive(item.EndDate)">close</md-icon>
                <md-icon v-else>done</md-icon>
              </md-table-cell>

              <md-table-cell class="fixed-width" :md-label="$t('controls')">
                <div class="controls">
                  <span class="animated-icon" @click="handleEditAwsAccount(item)">edit</span>
                  <md-tooltip md-direction="bottom">{{ $t('edit') }}</md-tooltip>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>

          <div v-else class="md-center pt-10">
            <h3>{{ $t('noConfigFound') }}</h3>
          </div>
          <div class="text-right pt-10">
            <md-button id="submit" class="md-raised md-primary md-round" @click="addAwsConfig()">
              {{ $t('create') }}
            </md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationStore } from '@/stores/configuration'

export default {
  data() {
    return {
      productIDCloud: '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}',
      selected: {},
      awsAccounts: [],
      loader: false,
    }
  },
  async beforeMount() {
    this.loader = true

    const product = this.companyStore.productData.companyHasProduct.find((p) => p.ProductID === this.productIDCloud)
    if (!product || this.isEmptyObject(product)) this.$router.push({ name: 'Company Products' })
    const token = this.userStore.token
    // laden und speichern der aws accounttypen
    var resTypes = await this.configurationStore.getConfigurationAccountTypes(token)
    var resConfig = await this.configurationStore.getConfiguration(product.ConfigurationID, token)
    var resConfigAws = await this.configurationStore.getConfigurationsAws(product.ConfigurationID, token)
    var resAwsStacks = await this.configurationStore.getAwsStacks(token)
    this.awsAccounts = this.configurationStore.configurationsAws
    this.tableData = this.awsAccounts
    this.loader = false
  },
  methods: {
    getActive(date) {
      return new Date() > new Date(date)
    },
    getReadableDate(input) {
      const date = new Date(input)
      let dateString = date.toLocaleDateString()
      return dateString
    },
    addAwsConfig() {
      this.$router.push({ name: 'Create Aws Config' })
    },
    onSelect(item) {
      this.selected = item
      this.configurationStore.configuration = item
      this.$router.push({
        name: 'Aws Account',
      })
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0
    },
    handleEditAwsAccount(item) {
      this.selected = item
      this.configurationStore.configuration = item
      this.$router.push({
        name: 'Aws Account',
      })
    },
  },
  computed: {},
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    return { companyStore, userStore, configurationStore }
  },
}
</script>

<style scoped>
.md-checkbox {
  margin: unset !important;
}
.md-center {
  text-align: center;
}
.controls {
  display: flex;
  justify-content: flex-end;
}
.fixed-width {
  width: 80px !important;
}
.md-table-head:last-child:not(:first-child) .md-table-head-label {
  padding-right: 0 !important;
}
.pt-10 {
  padding-top: 10px;
}
</style>
