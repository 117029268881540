<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
      <loader :loader="loader" />
      <div :key="renderKey" v-if="renderKey">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
            <h4 class="title">{{ $t('listOfStudents') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
              <md-table-toolbar>
                <div class="toolbar-left">
                  <md-field>
                    <div>
                      <label for="pages" id="perPage">{{ perPage }}</label>
                    </div>
                    <md-select v-model="pagination.perPage" name="pages">
                      <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>

                  <md-field class="ml-10">
                    <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="searchRecords"
                      v-model="searchQuery">
                    </md-input>
                  </md-field>
                </div>
                <md-field>
                  <md-button class="md-primary create-contact-button md-round" @click.native="handleCreate()">{{
                    $t('createNewContact') }}
                  </md-button>
                </md-field>
              </md-table-toolbar>
              <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                <md-table-cell :md-label="'#'">
                  {{ calculatePosition(index) }}
                </md-table-cell>

                <md-table-cell :md-label="$t('name')" md-sort-by="LastName">
                  <strong>{{ item.LastName }}</strong><br />{{ item.FirstName }}</md-table-cell>

                <md-table-cell :md-label="$t('email')">{{ item.Email }}</md-table-cell>
                <md-table-cell :md-label="$t('university')" v-if="langIsGerman">{{
                  item.UniversityNameDE? item.UniversityNameDE : item.UniversityNameDE="Registriert"
                }}</md-table-cell>
                <md-table-cell :md-label="$t('university')" v-if="!langIsGerman">{{
                  item.UniversityNameEN? item.UniversityNameEN : item.UniversityNameEN="Registered"
                }}</md-table-cell>

                <md-table-cell :md-label="$t('date')" md-sort-by="Created">{{ item.Created }}</md-table-cell>

                <md-table-cell :md-label="$t('actions')">
                  <md-button class="md-just-icon md-primary md-simple" @click.native="handleEdit(item)">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="bottom">{{ $t('edit') }}</md-tooltip>
                  </md-button>
                  <md-button v-if="allow('{134a5c9f-25c6-41e7-a417-08b18f1caad2}')"
                    class="md-just-icon md-danger md-simple" @click.native="handleDelete(item)">
                    <md-icon>close</md-icon>
                    <md-tooltip md-direction="bottom">{{ $t('delete') }}</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <div v-if="noResults" class="noresult-massage">{{ $t('noResultsFound') }}</div>
          <md-card-actions md-alignment="space-between" v-if="!noResults">
            <div class="">
              <p class="card-category">
                {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>
            <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total">
            </pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <!-- md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100 -->
    <div v-if="allow('{ab042df6-f2e8-4999-b94d-47e50569e1fd}')"
      class="md-layout-item md-size-33 md-large-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100 no-pad">
      <student-stats></student-stats>
    </div>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import Swal from 'sweetalert2'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useManagementStore } from '@/stores/management'
import StudentStats from '../Statistics/StudentStats.vue'
import { permissionMixin } from '../RoleManagement/permissionMixin'
import CompanyContactsList from '../CompanyContacts/CompanyContactsList.vue'

export default {
  mixins: [permissionMixin],
  components: {
    Pagination,
    StudentStats,
  },
  computed: {
    queriedData() {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      if (this.searchedData.length === 0 && this.searchQuery.length > 0) {
        result = []
      }

      // Apply custom sort before pagination
      result = this.customSort(result)

      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length
    },
  },
  data() {
    return {
      currentSort: 'Created',
      currentSortOrder: 'desc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: '',
      // propsToSearch: ["FirstName", "LastName", "Email"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      perPage: this.$t('perPage'),
      searchRecords: this.$t('searchRecords'),
      renderKey: 0,
      langIsGerman: this.$i18n.locale === 'de-DE',
      loader: false,
      noResults: false,
    }
  },
  methods: {
    customSort(items) {
      return items.sort((a, b) => {
        const sortBy = this.currentSort
        if (sortBy === 'Created') {
          // Ensure proper date parsing
          const dateA = new Date(a[sortBy])
          const dateB = new Date(b[sortBy])

          if (this.currentSortOrder === 'desc') {
            return dateB - dateA // Descending order
          }
          return dateA - dateB // Ascending order
        } else {
          if (this.currentSortOrder === 'desc') {
            return b[sortBy].localeCompare(a[sortBy])
          }
          return a[sortBy].localeCompare(b[sortBy])
        }
      })
    },
    changeTranslation() {
      this.renderKey += 1
      this.perPage = this.$t('perPage')
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
    handleCreate() {
      Swal.fire({
        title: this.$t('askCreateNewCustomer'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        if (result.isDismissed) return
        this.$router.push({
          name: 'Create New Contact',
        })
      })
    },
    async getStudentDomains() {
      await this.userStore.getAllStudentDomains()
    },
    handleEdit(item) {
      this.userStore.editContactID = item
      this.$router.push({ name: 'Edit Contact' })
    },
    async handleDelete(item) {
      var ask = Swal.fire({
        icon: 'warning',
        title: this.$t('delete'),
        html: `${this.$t('deleteContact')}? <br><br>${item.FirstName} ${item.LastName}<br><br>${item.Email}`,
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
        reverseButtons: true,
      }).then((result) => {
        return result
      })
      if (!(await ask).isConfirmed) return

      var sure = Swal.fire({
        icon: 'error',
        title: this.$t('deleteTitle'),
        html: this.$t('deleteText'),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
        reverseButtons: true,
      }).then((result) => {
        return result
      })
      if (!(await sure).isConfirmed) return
      this.loader = true
      var resDel = await this.managementStore.deleteContact(item.ID, this.userStore.token)
      this.loader = false

      if (resDel.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: `${this.$t('errorDeleteContact')}:<br><br>${resDel.data.Message}`,
        })

        return
      } else {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successDeleteContact'),
        })

        this.loader = true

        await Promise.all([this.getStudentDomains(), this.getAllVerifications(), this.getStudentContacts()])
        this.searchQuery = ''
        const first50Contacts = this.tableData.slice(0, 50)
        //get Uni for first 50 contacts
        await this.getStudentUni(first50Contacts)
        this.renderKey++
        this.updateFuseSearch()

        this.loader = false
        //get Uni for the rest of contacts
        const remainingContacts = this.tableData.slice(50)
        await this.getStudentUni(remainingContacts)
        this.updateFuseSearch()
        this.renderKey++
      }
    },
    deleteRow(item) {
      return
      let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id)
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1)
      }
    },
    async getStudentUni(table) {
      this.table = table.map((contact) => {
        const verification = this.allVerifications.find((verification) => verification.ContactID === contact.ID)
        if (verification) {
          contact.UniversityNameDE = verification.UniversityNameDE ? verification.UniversityNameDE : "Registrated"
          contact.UniversityNameEN = verification.UniversityNameEN ? verification.UniversityNameEN : "Registrated"
        }
        return contact
      })
    },
    async getStudentContacts() {
      this.tableData = []
      var response = await this.companyStore.getAllContacts(this.userStore.token)
      if (response.status === 401) this.$router.push({ name: 'Login' })
      if (response.status === 200) var tempTable = response.data
      else
        Swal.fire({
          title: this.$t('error'),
          text: response.statusText,
          icon: 'error',
        })
      tempTable.forEach((contact) => {
        if (contact.CompanyID === 'NULL' || contact.CompanyID === '') {
          this.tableData.push(contact)
        }
      })
    },
    updateFuseSearch() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ['FirstName', 'LastName', 'Email', 'UniversityNameDE', 'UniversityNameEN', 'Created'],
        findAllMatches: true,
        useExtendedSearch: true,
        shouldSort: true,
        ignoreLocation: true,
        threshold: 0.0,
      })
    },
    async getAllVerifications() {
      this.allVerifications = await this.userStore.getAllVerifications()
    },
  },
  async mounted() {
    this.$changePageTitle(this.$t('pageTitleContactList'))
    // Fuse search initialization.
    this.loader = true

    await Promise.all([this.getStudentDomains(), this.getAllVerifications(), this.getStudentContacts()])
    const first50Contacts = this.tableData.slice(0, 50)
    //get Uni for first 50 contacts
    await this.getStudentUni(first50Contacts)
    this.renderKey++
    this.updateFuseSearch()
    // this.fuseSearch = new Fuse(this.tableData, {
    //     keys: ['FirstName', 'LastName', 'Email', 'UniversityNameDE', 'UniversityNameEN', 'Created'],
    //     findAllMatches: true,
    //     useExtendedSearch: true,
    //     shouldSort: true,
    //     ignoreLocation: true,
    //     threshold: 0.0,
    //   })
    this.loader = false
    //get Uni for the rest of contacts
    const remainingContacts = this.tableData.slice(50)
    await this.getStudentUni(remainingContacts)
    this.updateFuseSearch()
    this.renderKey++
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        //get access to result items
        result = result.map((record) => record.item)
      }

      this.searchedData = result
    },
    perPage() {
      this.renderKey++
    },
    '$i18n.locale': {
      handler() {
        this.langIsGerman = this.$i18n.locale === 'de-DE'
      },
      deep: true,
    },
    searchedData: function () {
      if (this.searchedData.length) {
        this.noResults = false
      } else {
        this.noResults = true
        this.renderKeyTable++
      }
    },
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const managementStore = useManagementStore()
    return { userStore, companyStore, managementStore }
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    }
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  .toolbar-left {
    flex-direction: column;
  }
}

@media (min-width: 501px) {
  .toolbar-left {
    display: flex;
    flex-direction: row;
  }
}

.ml-10 {
  margin-left: 10px;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.email-hide {
  display: none;
}

td {
  word-wrap: break-word;
}

.noresult-massage {
  text-align: center;
  font-size: 20px;
  color: var(--brand-primary);
  margin-bottom: 20px;
}

@media screen and (max-width: 673px) {
  .create-contact-button {
    margin-top: 10px;
  }
}

.md-field::after {
  height: 0;
}

.toolbar-left {
  .md-field::after {
    height: 1px;
  }
}

.no-pad {
  padding: 0;
}
</style>
