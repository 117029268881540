<template>
  <div id="select-content">
    <loader :loader="loader" />
    <md-card class="md-card-select-mdesign">
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>content_paste</md-icon>
        </div>
        <h4 class="title">
          {{ $t('templates') }}
        </h4>
      </md-card-header>

      <md-card-content>
        <md-table v-model="configurationTemplates" class="md-table-mdesign-configuration" :key="renderKey">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="$t('name')">{{ item.Info }}</md-table-cell>

            <md-table-cell class="mw-20">
              <span class="animated-icon" @click.prevent="reduceCount(item)">remove</span>
            </md-table-cell>

            <md-table-cell class="mw-80" :md-label="$t('count')">
              <md-field class="input-field">
                <label></label>
                <md-input inputmode="numeric" min="0" max="100" type="number" v-model="item.Count"></md-input>
              </md-field>
            </md-table-cell>

            <md-table-cell class="mw-20 pr-40">
              <span class="animated-icon" @click.prevent="addCount(item)">add</span>
            </md-table-cell>

            <md-table-cell class="fix-width-25" :md-label="$t('limited')">
              <md-field class="input-field just-cont-sb">
                <span v-if="item.Enddate !== '9999-12-31'"><input type="date" v-model="item.Enddate" /> </span>
                <span class="md-input" v-else type="text">{{ $t('unlimited') }}</span>
                <div class="actions">
                  <div class="icon-container" :class="{ selected: item.Enddate === '9999-12-31' }">
                    <span class="animated-icon action-icon" @click="setDate(item)"> all_inclusive </span>
                    <span id="tooltip1" class="tooltip">{{ $t('unlimited') }}</span>
                  </div>
                  <div class="icon-container">
                    <span class="animated-icon action-icon" @click="deleteTemplate(item)"> close </span>
                    <span id="tooltip1" class="tooltip">{{ $t('delete') }}</span>
                  </div>
                </div>
              </md-field>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <div class="md-layout md-alignment-center">
          <div class="md-layout-item">
            <md-field>
              <md-button class="md-simple md-round btn-wi-150" @click.native="backStep()">{{ $t('back') }}</md-button>
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field class="content-right">
              <md-button :disabled="!templateIsSelected" class="md-primary btn-wi-150" @click.native="nextStep()">{{
                $t('further')
              }}</md-button>
            </md-field>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { useConfigurationProductLevelStore } from '../../../stores/configurationProductLevel.js'
import { useUserStore } from '../../../stores/user.js'
import { useCompanyStore } from '../../../stores/company.js'
import Swal from 'sweetalert2'

export default {
  components: {},
  computed: {
    templateIsSelected() {
      return this.preSelectedConfiguration !== '' ? true : false
    },
  },
  data() {
    return {
      renderKey: 0,
      configurationTemplates: [],
      preSelectedConfiguration: {},
      loader: false,
      showControls: false,
    }
  },
  async beforeMount() {
    this.loader = true
    this.preSelectedConfiguration = this.configurationProductLevelStore.configurationVorlage.ID
    if (this.configurationProductLevelStore.product.ID === '') {
      this.$router.push({ name: 'Company Products' })
      return
    }
    if (this.userStore.role.includes('Administrator')) this.showControls = true
    this.setLocalTemplates()

    this.loader = false
  },
  methods: {
    async deleteTemplate(template) {
      var res = await Swal.fire({
        position: 'middle-end',
        icon: 'warning',
        title: this.$t('deleteTitle'),
        html: this.$t('deleteTemplateText1') + template.Info + this.$t('deleteTemplateText2'),
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })

      if (res.isDismissed || res.isDenied) return
      const token = this.userStore.token
      if (template.ID === this.preSelectedConfiguration) this.preSelectedConfiguration = ''
      var response = await this.configurationProductLevelStore.deleteConfigurationTemplate(template, token)
      if (response === true) {
        this.configurationTemplates = this.configurationTemplates.filter((item) => item.ID !== template.ID)
      }
    },
    addCount(item) {
      this.loader = true
      this.configurationTemplates.forEach((template) => {
        if (template.ID !== item.ID) template.Count = 0
      })
      if (this.preSelectedConfiguration !== item.ID) this.preSelectedConfiguration = item.ID
      item.Count++
      this.renderKey++
      this.loader = false
    },
    reduceCount(item) {
      if (item.Count > 1) {
        item.Count--
      } else if (item.Count === 1) {
        item.Count = 0
        this.preSelectedConfiguration = ''
      }
      this.renderKey++
    },
    backStep() {
      //router from
      this.$router.back()

      //this.$router.push({ name: 'Mdesign Configuration Manager' })
    },
    async nextStep() {
      const token = this.userStore.token
      var res = await this.configurationProductLevelStore.createMdesignConfigurationModel(
        this.preSelectedConfiguration,
        token,
      )
      if (res === undefined) return
      const template = this.configurationTemplates.find((temp) => temp.ID === this.preSelectedConfiguration)
      this.configurationProductLevelStore.configurationVorlage.Count = template.Count
      this.configurationProductLevelStore.configurationVorlage.Enddate = template.Enddate
      this.configurationProductLevelStore.setConfigurationModelCounts(template.Count)
      this.configurationProductLevelStore.setConfigurationModelEnddate(template.Enddate)
      this.configurationProductLevelStore.configurationModel.valid = true
      this.$router.push({ name: 'Mdesign Configuration Manager' })
    },
    setDate(item) {
      if (this.configurationProductLevelStore.licenceCreatorModel.Enddate !== '') {
        item.Enddate === '9999-12-31'
          ? (item.Enddate = this.configurationProductLevelStore.licenceCreatorModel.Enddate)
          : (item.Enddate = '9999-12-31')
      } else {
        item.Enddate = item.Enddate === '9999-12-31' ? this.companyStore.getInOneYear() : '9999-12-31'
      }
      this.renderKey++
    },
    setLocalTemplates() {
      const copyTemplates = JSON.parse(JSON.stringify(this.configurationProductLevelStore.configurationTemplates))
      this.configurationTemplates = copyTemplates
      this.configurationTemplates.forEach((temp) => {
        temp.Count = 0
        temp.Enddate =
          this.configurationProductLevelStore.licenceCreatorModel.EndDate !== ''
            ? this.configurationProductLevelStore.licenceCreatorModel.EndDate
            : this.companyStore.getInOneYear()
      })
      if (this.configurationProductLevelStore.configurationVorlage.ID !== '') {
        this.configurationTemplates.forEach((template) => {
          if (template.ID === this.configurationProductLevelStore.configurationVorlage.ID) {
            template.Count = this.configurationProductLevelStore.configurationVorlage.Count
            template.Enddate = this.configurationProductLevelStore.configurationVorlage.Enddate
          }
        })
      }
    },
  },

  setup() {
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { configurationProductLevelStore, userStore, companyStore }
  },
  watch: {
    preSelectedConfiguration(newVal) {
      if (newVal === '') {
        this.configurationProductLevelStore.resetConfigurationVorlage()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.md-table-row:hover {
  cursor: pointer;
}
.btn-wi-150 {
  width: 150px;
}

.md-select-menu {
  max-width: none;
}

body > div.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
  max-width: unset !important;
}

input[type='number'] {
  background: transparent;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.md-table-cell {
  .md-table-cell-container {
    span {
      display: flex !important;
      align-items: center !important;
    }
  }
}

@keyframes changeGradient {
  /* Start from the left */
  0% {
    background-position: -100% 0;
  }

  /* Move to the right */
  100% {
    background-position: 100% 0;
  }
}

.md-table-row .animated-icon {
  visibility: hidden;
}

.md-table-row:hover .animated-icon {
  visibility: visible;
}

.content-right {
  display: flex;
  justify-content: flex-end;
}

.md-field:after {
  display: none !important;
}

.swal2-container {
  z-index: 100;
}

.modal-content {
  z-index: 99 !important;
}

.md-card-select-mdesign {
  max-width: 1200px;
}

#select-content {
  display: flex;
  justify-content: center;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
.action-icon {
  padding: 0 5px;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  width: auto;
  max-width: 200px; /* Adjust as needed */
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 1000;
  left: -0%;
  top: -100%;
  font-size: 13px;
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.last-icon-container {
  margin-left: auto; /* Push the last icon container to the end of the table cell */
}
.md-field:before {
  display: none;
}
</style>
