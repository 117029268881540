import {
  ApiNames,
  httpGetAuth,
  httpPost,
  httpGetByUrl,
  httpGetAuthFileDownload,
  httpPostAvatar,
  httpPostEducationProof,
  httpPostStudentEmail,
  httpDelete,
  httpPostAuth,
} from '@/libs/httpHelper'
import { defineStore } from 'pinia'
import claimTypes from 'claimtypes'
import * as jose from 'jose'
import {
  sendEmailConfimation,
  sendEmailTfaAuthentication,
  sendEmailResetLockout,
  sendEmailConfirmationNewsLetter,
} from '../libs/emailSender'

export const AccessLevel = {
  admin: 'Admin',
  member: 'Member',
  outcast: 'Outcast',
  student: 'Student',
  licenser: 'Licenser',
  firm: 'Firm',
  manufacturer: 'Manufacturer',
  viewer: 'Viewer',
}
export const tokenHeader = {
  name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
}

export const useUserStore = defineStore({
  id: 'userState',
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      valid: false,
      role: '',
      roleID: '',
      permissions: [],
      token: '',
      tfa: {
        Enabled: null,
        Exp: '',
        Verified: false,
      },
      lockout: {
        Exp: '',
        Attempts: -1,
      },
      exp: '',
      userData: {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: true,
        Language: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
        Image: '',
        AvatarImage: '/Default_profileImage.png',
      },
      userAddress: {
        City: '',
        Country: '',
        ZipCode: '',
        Street: '',
        Housenumber: '',
      },
      userContacts: {},
      editContactID: '',
      editContactProducts: [],
      userProductsID: {},
      userProducts: {},
      userFileCount: {
        RoloffMatek: 0,
      },
      userDownloadFile: {
        RoloffMatek: '',
      },
      theme: 'white',
      studentDomains: [],
    }
  },
  persist: true,
  getters: {
    // ...
  },
  mutations: {},
  actions: {
    async loginUser(email, password) {
      //userToken = token;
      this.valid = false
      var obj = {
        Email: email,
        Password: password,
      }
      var base64 = btoa(JSON.stringify(obj))
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var response = await httpPost(ApiNames.authorization, base64, customConfig)

      if (response?.data.Message === 'The request is invalid.') {
        if (response?.data.ModelState.locked) {
          this.lockout.Exp = response.data.ModelState.exp[0]
          this.valid = false

          this.token = response.data.ModelState.token[0]

          var resUser = await this.getContactIDbyEmail(email, this.token)
          var user = resUser.data[0]

          await sendEmailResetLockout(email, this.token, user.FirstName, user.LastName)

          return 'user locked out'
        }
        if (response?.data.ModelState.password[0] === 'wrong password') {
          this.lockout.Attempts = response?.data.ModelState.attempts[0]
          return 'wrong password'
        }
      }

      if (response?.data.Message === 'unknown username') {
        return 'unknown username'
      }

      // if (response?.data.Message === 'not authorised') {
      //   return 'email is not confirmed'
      // }

      // check if tfa and split tfa from token
      let responseData
      if (response.data.tfa) {
        var decode = atob(response.data.tfa)
        this.token = response.data.token
        var tfa = JSON.parse(decode)
        this.tfa.Enabled = true
        this.tfa.Exp = tfa.exp
        this.tfa.Verified = false
        var code = tfa.code

        var contact = {
          FirstName: '',
          LastName: '',
        }

        var resMd_user = await this.getContactIDbyEmail(email, this.token)
        if (resMd_user.status !== 200) return 'error tfa'

        contact.FirstName = resMd_user.data[0].FirstName
        contact.LastName = resMd_user.data[0].LastName
        var resEmailtfa = await sendEmailTfaAuthentication(code, contact.FirstName, contact.LastName, email)

        responseData = JSON.parse(atob(response.data.token.split('.')[1]))
      } else {
        this.token = response.data
        responseData = JSON.parse(atob(response.data.split('.')[1]))
      }

      var checkAcc = await this.checkAccountStatus(email)

      if (checkAcc === 'not_verified') return 'email is not confirmed'

      if (checkAcc === 'user_disabled') return 'user disabled'

      const userID = responseData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
      if (response?.status === 200 && response?.data) {
        var dataObj = response.data
        if (dataObj.tfa) {
          this.token = dataObj.token
        } else {
          this.token = dataObj
        }
        if (this.token) {
          try {
            const response = await httpGetByUrl(window.location.origin + '/publickey.txt')

            if (response.status == 200 && response.data) {
              const alg = 'RS256'
              const spki = response.data
              const publicKey = await jose.importSPKI(spki, alg)
              const { payload, protectedHeader } = await jose.jwtVerify(this.token, publicKey, {
                issuer: 'my.mdesign.online',
                audience: 'my.mdesign.online',
                alg: alg,
              })
              var tokenData = payload
              this.role = tokenData[claimTypes.microsoft.role]
              this.valid = true
              await this.getUserData(userID)
            }
          } catch (err) {}
        }
      }
    },
    async checkAccountStatus(email) {
      let md_user = {
        Verfied: null,
        Disable: null,
      }

      var resMd_user = await this.getUserDataByEmail(email, this.token)
      if (resMd_user.status === 200) {
        md_user.Verfied = resMd_user.data[0].Verified
        md_user.Disable = resMd_user.data[0].Disable
        this.roleID = resMd_user.data[0].MDUserRoleID
      }

      if (!md_user.Verfied || md_user.Verfied === null) return 'not_verified'
      if (md_user.Disable || md_user.Disable === null) return 'user_disabled'

      return true
    },
    async validateToken(dataObj) {
      this.token = dataObj
      if (this.token) {
        try {
          const response = await httpGetByUrl(window.location.origin + '/publickey.txt')

          if (response.status == 200 && response.data) {
            const alg = 'RS256'
            const spki = response.data
            const publicKey = await jose.importSPKI(spki, alg)
            const { payload, protectedHeader } = await jose.jwtVerify(this.token, publicKey, {
              issuer: 'my.mdesign.online',
              audience: 'my.mdesign.online',
              alg: alg,
            })

            var tokenData = payload
            this.role = tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            this.userData.Email = tokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
          }
        } catch (err) {}
      }
    },
    async confirmEmail(token) {
      this.token = token
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var response = await httpPost(ApiNames.confirmemail, token, customConfig)
      if (response?.status === 200) {
        return true
      } else return false
    },
    async createUser(dataObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var response = await httpPost(ApiNames.createuser, dataObj, headerConfig)
      if (response?.status === 200) {
        return response.data
      } else return false
    },
    async registerUser(props) {
      //userToken = token;
      var obj = {
        Email: props.Email,
        Password: props.Password,
        Salutation: props.Salutation,
        Firstname: props.FirstName,
        Lastname: props.LastName,
        RoleID: props.RoleID,
        Country: props.Country,
        CompanyName: props.CompanyName,
        Street: props.Street,
        Housenumber: props.Housenumber,
        City: props.City,
        Postcode: props.ZipCode,
        CustomerNumber: props.MdesignCustomerNumber,
      }

      // Convert object to a string

      const jsonString = JSON.stringify(obj)
      const base64String = btoa(unescape(encodeURIComponent(jsonString)))
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      let registrationLinkType = 'student'
      const registrationLink = window.location.href
      if (registrationLink.includes('roloff-matek')) {
        registrationLinkType = 'roloff-matek'
      }

      var response = await httpPost(ApiNames.register, base64String, customConfig)
      if (response?.status === 200 && response?.data) {
        var dataObj = response.data
        this.token = dataObj
        if (this.token) {
          try {
            await sendEmailConfimation(this.token, props.Email, props.isRoloffMatek, props.FirstName, props.LastName)
            await sendEmailConfirmationNewsLetter(
              props.Salutation,
              props.FirstName,
              props.LastName,
              props.Email,
              registrationLinkType,
            )
          } catch (err) {}
        }
        return true
      } else if (
        response?.status === 400 &&
        response?.data.Message ===
          'Name ' + props.Email + " is already taken.Email '" + props.Email + "' is already taken."
      ) {
        const userExist = 'userExist'
        return userExist
      } else {
        return false
      }
    },
    async getUserData(userID) {
      var responseContact = await httpGetAuth(
        ApiNames.contact,
        {
          id: userID,
        },
        {},
        this.token,
      )
      var responseAddressID = await this.getUserAddressID(userID)
      let responseAddressData
      if (responseAddressID) {
        var responseAddress = await httpGetAuth(
          ApiNames.address,
          {
            id: responseAddressID,
          },
          {},
          this.token,
        )
        responseAddressData = responseAddress.data[0]
      } else {
        responseAddressData = {
          Street: '',
          Housenumber: '',
          ZipCode: '',
          City: '',
          Country: '',
        }
      }
      if (responseContact?.status === 200 && responseContact?.data) {
        var dataObj = responseContact.data[0]
        var addressObj = responseAddressData
        this.userData = dataObj
        this.userAddress = addressObj
      }
    },
    async getUserAddressID(userID) {
      var responseAddressID = await httpGetAuth(
        ApiNames.contacthasaddress,
        {
          id: userID,
        },
        {},
        this.token,
      )
      if (responseAddressID.status === 200) {
        let userAddressID = responseAddressID.data[0].AddressID
        return userAddressID
      }
      return false
    },
    async updateUserData(updatedUserDataObj, updateUserAddressObject) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      var responseUpdateData = await httpPost(ApiNames.updateuserdata, updatedUserDataObj, headerConfig)
      updateUserAddressObject.ID = await this.getUserAddressID(this.userData.ID)
      var responseUpdateAddress = await httpPost(ApiNames.updateuseraddress, updateUserAddressObject, headerConfig)
      if (responseUpdateData?.status === 200 && responseUpdateAddress.status === 200) {
        return 200
      }
    },
    createDefaultUserData() {
      this.token = ''
      this.tfa = {
        Enabled: null,
        Exp: '',
        Verified: false,
      }
      this.lockout = {
        Exp: '',
        Attempts: -1,
      }
      this.exp = ''
      this.role = ''
      this.roleID = ''
      this.permissions = []
      this.userData = {
        ID: '',
        CompanyID: '',
        Salutation: '',
        Title: '',
        FirstName: '',
        LastName: '',
        Gender: '',
        Phone: '',
        Email: '',
        Position: '',
        MainContact: true,
        Language: '',
        CreatedBy: '',
        Created: '',
        ModifiedBy: '',
        Modified: '',
        Image: '',
        AvatarImage: '',
      }
      this.userAddress = {
        City: '',
        Country: '',
        ZipCode: '',
        Street: '',
        Housenumber: '',
      }
      ;(this.userContacts = {}),
        (this.userProductsID = {}),
        (this.userProducts = {}),
        (this.userFileCount = {
          RoloffMatek: 0,
        })
      this.userDownloadFile = {
        RoloffMatek: '',
      }
    },
    async generateDataFileID() {
      var email = 'license.service@tedata.de'
      var password = "mzEQG'{!%jSm7tBH"

      var obj = {
        Email: email,
        Password: password,
      }

      const startDate = new Date().toISOString().split('T')[0]
      const today = new Date()
      today.setFullYear(today.getFullYear() + 2)
      const endDate = today.toISOString().split('T')[0]
      const userObj = {
        projectName: 'Roloff/Matek',
        port: '28064',
        version: 19,
        templateName: 'RM_2023_v002_mit_connect',
        firstName: this.userData.FirstName,
        lastName: this.userData.LastName,
        email: this.userData.Email,
        startDate: startDate,
        endDate: endDate,
        uuid: this.userData.ID,
        type: 'template',
        hostID: '',
      }
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var base64UserObj = btoa(JSON.stringify(userObj))
      var base64 = btoa(JSON.stringify(obj))
      var response = await httpPost(ApiNames.authorization, base64, customConfig)

      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      if (response?.status === 200 && response?.data) {
        var responseLicense = await httpPost(ApiNames.generatelecense, base64UserObj, headerConfig)
        if (responseLicense?.status === 200 && responseLicense?.data) {
          var dataObj = responseLicense.data
          return dataObj
        }
      }
    },
    async createTokenByEmail(email) {
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var objEmailReciever = {
        Email: email,
      }
      var base64Reciever = btoa(JSON.stringify(objEmailReciever))

      var responseToken = await httpPost(ApiNames.createtoken, base64Reciever, customConfig)
      if (responseToken?.status === 200 && responseToken?.data) {
        return responseToken.data
      }
      return null
    },
    async createAddress(addressObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var addressIDresponse = await httpPost(ApiNames.createaddress, addressObj, headerConfig)
      if (addressIDresponse?.status === 200 && addressIDresponse?.data) {
        return addressIDresponse.data
      }
      return null
    },
    async createLinkContractHasAddress(addressID, userID, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      const AddressTypeID = '{4e4103b7-b968-412b-9c0b-d154585f0eb1}'
      var obj = {
        AddressID: addressID,
        ContactID: userID,
        AddressTypeID: AddressTypeID,
      }

      var response = await httpPost(ApiNames.createcontacthasaddress, obj, headerConfig)

      if (response?.status === 200) {
        return response.status
      }
      return null
    },
    async createContactHasProduct(contactID, productID, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var obj = {
        ContactID: contactID,
        ProductID: productID,
        FileCount: 0,
      }
      var response = await httpPost(ApiNames.createcontacthasproduct, obj, headerConfig)
      if (response?.status === 200) {
        return response.status
      }
      return null
    },
    async getUserProducts(contactID) {
      var response = await httpGetAuth(
        ApiNames.contacthasproducts,
        {
          id: contactID,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response.data
      }
      if (response?.status === 401) {
        return 'unauthorized'
      }
      return null
    },
    async getAllProductsInfo() {
      var response = await httpGetAuth(
        ApiNames.products,
        {
          id: this.userData.ID,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response.data
      }
      return null
    },
    async createFileReference(dataFileID, tableName, idFromTable) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      var obj = {
        DataFileID: dataFileID,
        IDFromTable: idFromTable,
        Tablename: tableName,
        Language: 'de',
      }
      var response = await httpPost(ApiNames.createfilereference, obj, headerConfig)
      if (response?.status === 200) {
        return response.status
      }
      return null
    },
    async updateContactHasProduct(productID, fileCount) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      var obj = {
        ContactID: this.userData.ID,
        ProductID: productID,
        FileCount: fileCount,
      }
      var response = await httpPost(ApiNames.updatecontacthasproduct, obj, headerConfig)
      if (response?.status === 200) {
        return response.status
      }
      return null
    },
    async getFileReference(productID) {
      var response = await httpGetAuth(
        ApiNames.filereference,
        {
          idFromTable: this.userData.ID,
          idFromTable2: productID,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response
      }
      return null
    },
    async getDownloadFile(dataFileID, changeDownloadCount = false) {
      var response = await httpGetAuthFileDownload(
        ApiNames.downloadfile,
        {
          id: dataFileID,
          isDownloadCounted: changeDownloadCount,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response
      }
      return null
    },
    async uploadAvatar(file) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
      var formData = new FormData()
      formData.append('avatar', file)
      var response = await httpPostAvatar(ApiNames.uploadavatar, this.userData.ID, formData, headerConfig)
      if (response?.status === 200) {
        return response
      }
      return null
    },
    async getAvatar() {
      var response = await httpGetAuth(
        ApiNames.filereferencebytable,
        {
          idFromTable: this.userData.ID,
          tableName: 'contact',
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        const avatarFileID = response.data[0].DataFileID
        var responseAvatarImg = await httpGetAuthFileDownload(
          ApiNames.downloadfile,
          {
            id: avatarFileID,
            isDownloadCounted: false,
          },
          {},
          this.token,
        )
        if (responseAvatarImg?.status === 200) {
          return responseAvatarImg
        }
        return null
      }
      return null
    },
    async uploadEducationProof(file, productID) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
      const formData = new FormData()
      formData.append('ima', file)
      var response = await httpPostEducationProof(
        ApiNames.uploadima,
        this.userData.ID,
        productID,
        formData,
        headerConfig,
      )
      if (response?.status === 200) {
        return response
      }
      // if (response?.status === 400) {
      //   return "file was not uploaded";
      // }
      if (response?.data.Message === 'maximum file number reached') {
        return 'maximum file number reached'
      }
      if (response?.data.Message === 'this file is not an image or pdf') {
        return 'this file is not an image or pdf'
      }
      if (response?.data.Message === 'this file is not an image or pdf') {
        return 'this file is not an image or pdf'
      }
      return null
    },
    async getUserVerification(productID, editContactID) {
      //editContactID is used to get the verification of the user that is being edited
      let id = editContactID ? editContactID : this.userData.ID
      var responseUserVerification = await httpGetAuth(
        ApiNames.verification,
        {
          id,
        },
        {},
        this.token,
      )
      if (responseUserVerification?.status === 200) {
        const responseData = responseUserVerification.data
        const verificationObject = responseData.filter((object) => object?.ProductID === productID)
        return verificationObject
      } else if (responseUserVerification?.status === 401) {
        return 'unauthorized'
      } else {
        return null
      }
    },
    async getAllVerifications() {
      var responseAllVerification = await httpGetAuth(ApiNames.allverifications, {}, {}, this.token)
      if (responseAllVerification?.status === 200) {
        return responseAllVerification.data
      } else {
        return null
      }
    },
    async getFileReferenceByTable(idFromTable, tableName) {
      var response = await httpGetAuth(
        ApiNames.filereferencebytable,
        {
          idFromTable,
          tableName,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response
      } else {
        return
      }
    },
    async getDataFileDetails(dataFileID) {
      var response = await httpGetAuth(
        ApiNames.datafile,
        {
          id: dataFileID,
        },
        {},
        this.token,
      )
      if (response?.status === 200) {
        return response
      } else {
        return null
      }
    },
    async createVerificationByEmail(productID, studentEmail) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      }
      var response = await httpPostStudentEmail(
        ApiNames.createverificationbystudentemail,
        this.userData.ID,
        productID,
        studentEmail,
        headerConfig,
      )
      return response
    },
    async verifyStudentEmail(productID, contactID, token) {
      this.userData.ID = contactID
      this.token = token
      const verificationObject = await this.getUserVerification(productID)
      if (verificationObject === 'unauthorized') {
        return 'unauthorized'
      }
      if (
        verificationObject[0].Status === 2 ||
        verificationObject[0].Status === 3 ||
        verificationObject[0].Status === 4
      ) {
        const verified = verificationObject[0].ID
        var responseConfirmedEmail = await httpGetAuth(ApiNames.confirmedmail, { verified }, {}, token)
        return responseConfirmedEmail.data
      }

      return null
    },
    async deleteImaFile(dataFileID, verificationID, imaDataNumber) {
      const headerConfig = {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
      // Delete file_reference
      const responseDeleteFileReference = await httpDelete(
        ApiNames.deletefilereferencebydatafileid,
        dataFileID,
        headerConfig,
      )
      if (responseDeleteFileReference?.status === 200) {
        // (if exists) Delete download
        const responseGetDownload = await this.getDownload(this.userData.ID)
        const downloadData = responseGetDownload?.data ? responseGetDownload.data : []
        downloadData.forEach(async (element) => {
          if (element.DataFileID === dataFileID) {
            await httpDelete(ApiNames.deletedownload, dataFileID, headerConfig)
          }
        })
        const responseDeleteDataFile = await httpDelete(ApiNames.deletedatafile, dataFileID, headerConfig)

        if (responseDeleteDataFile?.status === 200) {
          const responseUpdateVerification = await httpPost(
            ApiNames.updateimacount,
            {
              ID: verificationID,
              ContactID: this.userData.ID,
              ImaData: imaDataNumber,
            },
            headerConfig,
          )
          if (responseUpdateVerification?.status === 200) {
            return responseUpdateVerification.status
          }
          return null
        }
        return null
      }
      return null
    },
    async getContactIDbyEmail(email, token) {
      var response = await httpGetAuth(
        ApiNames.contactbyemail,
        {
          email,
        },
        {},
        token,
      )
      return response
    },
    async updateUserWithContactID(updateObj, token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      var responseUpdateData = await httpPost(
        ApiNames.updateuser,
        {
          ...updateObj,
        },
        headerConfig,
      )
      if (responseUpdateData?.status === 200) {
        return 200
      }
    },
    async getUserDataByEmail(username, token) {
      var response = await httpGetAuth(
        ApiNames.userbyusername,
        {
          username,
        },
        {},
        token,
      )
      return response
    },
    async checkOldPassowrd(email, oldPassword) {
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var obj = {
        Email: email,
        Password: oldPassword,
      }
      var base64 = btoa(JSON.stringify(obj))
      var response = await httpPost(ApiNames.authorization, base64, customConfig)
      return response
    },
    async createProductToContact(contactID, productID) {
      var dataObj = {
        ContactID: contactID,
        ProductID: productID,
      }
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      var response = await httpPost(ApiNames.createcontacthasproduct, dataObj, headerConfig)
      return response
    },
    async changeStatusOfVerification(
      productID,
      contactID,
      newStatus,
      verificationID,
      verifiedStatus,
      selectedStudentDomain,
    ) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const newStatusNumber = parseInt(newStatus)
      var dataObj = {
        ID: verificationID,
        ContactID: contactID,
        ProductID: productID,
        Status: newStatusNumber,
        Verified: verifiedStatus,
        StudentDomainID: selectedStudentDomain,
      }
      var response = await httpPost(ApiNames.updateverification, dataObj, headerConfig)
      return response
    },
    async generateStudentLicense(firstName, lastName, email, contactID, verificationID, templateName) {
      var emailLicence = 'license.service@tedata.de'
      var password = "mzEQG'{!%jSm7tBH"

      var obj = {
        Email: emailLicence,
        Password: password,
      }

      const startDate = new Date().toISOString().split('T')[0]
      const today = new Date()
      today.setFullYear(today.getFullYear() + 2)
      const endDate = today.toISOString().split('T')[0]
      const userObj = {
        projectName: templateName,
        port: '28064',
        version: 20,
        templateName: templateName,
        firstName: firstName,
        lastName: lastName,
        email: email,
        startDate: startDate,
        endDate: endDate,
        uuid: contactID,
        type: 'template',
        hostID: '',
      }
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var base64UserObj = btoa(unescape(encodeURIComponent(JSON.stringify(userObj))))

      var base64 = btoa(JSON.stringify(obj))
      var response = await httpPost(ApiNames.authorization, base64, customConfig)
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      if (response?.status === 200 && response?.data) {
        var responseLicense = await httpPost(ApiNames.generatelecense, base64UserObj, headerConfig)
        if (responseLicense?.status === 200 && responseLicense?.data) {
          var dataObj = responseLicense.data
          await this.createFileReference(dataObj.id, 'verification', verificationID)
          await this.createDownload(contactID, responseLicense.data.id)
          let licenceFile = await this.getDownloadFile(dataObj.id)
          return { ...licenceFile, dataObj }
        } else {
          return responseLicense
        }
      }
    },
    async getAllStudentDomains() {
      var response = await httpGetAuth(ApiNames.studentdomains, {}, {}, this.token)
      if (response?.status === 200) {
        this.studentDomains = response.data
      } else {
        return null
      }
    },
    async updateDownloadCount() {
      var response = await httpGetAuth(
        ApiNames.download,
        {
          id: this.userData.ID,
        },
        {},
        this.token,
      )
      if (response.status === 200) {
        var responseCountUpdate = await httpPost(
          ApiNames.updatedownload,
          {
            id: response.data[0].ID,
          },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          },
        )
      }
    },
    async createDownload(contactID, dataFileID) {
      var responseCreateDownload = await httpPost(
        ApiNames.createdownload,
        {
          ContactID: contactID,
          DataFileID: dataFileID,
        },
        {
          headers: { Authorization: `Bearer ${this.token}` },
        },
      )
      return responseCreateDownload
    },
    async getDownload(contactID) {
      var response = await httpGetAuth(
        ApiNames.download,
        {
          id: contactID,
        },
        {},
        this.token,
      )
      if (response.status === 200) {
        return response.data
      } else {
        return null
      }
    },
    async getStudentDomainByEmailDomain(emailDomain) {
      var response = await httpGetAuth(
        ApiNames.studentdomainbydomain,
        {
          domain: emailDomain,
        },
        {},
        this.token,
      )
      return response
    },
    async createVerification(productID, contactID) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.createverification,
        {
          ContactID: contactID,
          ProductID: productID,
        },
        headerConfig,
      )
      return response
    },
    async createVerificationForExistedStudentDomain(productID, contactID, studentEmail) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.createverification,
        {
          ContactID: contactID,
          ProductID: productID,
          StudentEmail: studentEmail,
        },
        headerConfig,
      )
      return response
    },
    async updateValidityStartDate(verificationID, contactID, productID, studentDomainID, newStartDate) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.updateverification,
        {
          ID: verificationID,
          ContactID: contactID,
          ProductID: productID,
          ValidityStart: newStartDate,
          StudentDomainID: studentDomainID,
          Status: 3,
        },
        headerConfig,
      )
      return response
    },
    async getOrderingByContact(contactID) {
      const response = await httpGetAuth(
        ApiNames.orderingbycontact,
        {
          id: contactID,
        },
        {},
        this.token,
      )
      return response
    },
    async createOrdering(CompanyID, contactID, productID, amount) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.createordering,
        {
          CompanyID,
          ContactID: contactID,
          ProductID: productID,
          Price: amount,
        },
        headerConfig,
      )
      return response
    },
    async getProductDetailsByID(productID) {
      const response = await httpGetAuth(
        ApiNames.product,
        {
          id: productID,
        },
        {},
        this.token,
      )
      return response
    },
    async enableTFA() {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.enabletfa,

        {},
        headerConfig,
      )
      return response
    },
    async disableTFA() {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.disabletfa,

        {},
        headerConfig,
      )
      return response
    },
    async getCodeTFA() {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPostAuth(ApiNames.getcode, {}, {}, this.token)
      return response
    },
    async verifyTFA(code) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpGetAuth(ApiNames.verifycode, { code: code }, {}, this.token)
      return response
    },
    async resetLockout(token) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` },
      }
      const config = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
      const response = await httpPost(ApiNames.resetlockout, token, { ...config, ...headerConfig }) //await httpPostAuth(ApiNames.resetlockout, { token: token }, {}, { })
      return response
    },
    async createContactHasConsentType(obj, token) {
      const response = await httpPostAuth(ApiNames.createcontacthasconsenttype, obj, {}, token)
      return response
    },
    async getContactHasConsentTypeByContactID(contactID, token) {
      var obj = {
        id: contactID,
      }
      const response = await httpGetAuth(ApiNames.contacthasconsenttype_contactid, obj, {}, token)
      return response
    },
    async changeTestOrderValue(orderID, contactID, productID, testOrderValue) {
      const headerConfig = {
        headers: { Authorization: `Bearer ${this.token}` },
      }
      const response = await httpPost(
        ApiNames.updateordering,
        {
          ID: orderID,
          ContactID: contactID,
          ProductID: productID,
          Testorder: testOrderValue,
        },
        headerConfig,
      )
      return response
    },
  },
})
