<template>
  <div class="page-content">
    <loader :loader="loader" />
    <div class="img-title-registration">
      <img slot="title" :src="logo" alt="MyMdesignLogo" class="img-logo" />
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <form slot="inputs" action="" method="post" id="registrationForm">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-default">
            <h2 class="title text-center registration-title">
              {{ $t('registration') }}
            </h2>
          </md-card-header>
          <div class="md-collapse private-business-role">
            <!-- <div class="theme-color">{{ $t("businessRole") }}</div>
             <md-switch v-model="isPrivateRole" class="registration-role"></md-switch>
             <div class="theme-color">{{ $t("privatRole") }}</div> -->
          </div>
          <md-card-content class="input-fields">
            <div class="md-layout-item md-xsmall-size-100 md-size-50">
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" required>
                  <label>{{ $t('email') }} *</label>
                  <md-input v-model="email" type="email" autocomplete="new-password"> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="password"
                :rules="{
                  required: true,
                  regex: regExValid,
                }"
                v-slot="{ passed, failed }"
              >
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]" required>
                  <label>{{ $t('password') }} *</label>
                  <md-input v-model="password" type="password" autocomplete="new-password"> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error password" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success password" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
                <div v-show="failed" class="password-rule">
                  {{ $t('setPasswordRule') }}
                </div>
              </ValidationProvider>
              <ValidationProvider
                name="checkboxStudent"
                v-if="isPrivateRole"
                :rules="{ required: { allowFalse: false } }"
              >
                <div class="student-checkbox">
                  <label class="line">
                    <input v-model="checkboxStudent" type="checkbox" required class="my-checkbox student-checkbox" />
                    <div class="student-checkbox">
                      {{ $t('studentCheckbox') }}
                    </div>
                  </label>
                </div>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-size-50 right-side-menu">
              <div class="md-layout-item md-xsmall-size-100 md-size-100">
                <md-field>
                  <label for="salutation">{{ $t('salutation') }}</label>
                  <md-select v-model="salutation" name="salutation" id="salutation">
                    <md-option value="Herr">{{ $t('mr') }}</md-option>
                    <md-option value="Frau">{{ $t('ms') }}</md-option>
                    <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
                  </md-select>
                </md-field>

                <ValidationProvider rules="required">
                  <md-field>
                    <label>{{ $t('firstName') }} *</label>
                    <md-input v-model="firstname" type="text"></md-input>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider rules="required">
                  <md-field>
                    <label>{{ $t('lastName') }} *</label>
                    <md-input v-model="lastname" type="text"></md-input>
                  </md-field>
                </ValidationProvider>
                <select-country value="DE"></select-country>
                <div v-if="!isPrivateRole">
                  <ValidationProvider
                    :rules="{
                      required: !isPrivateRole,
                    }"
                  >
                    <md-field>
                      <label>{{ $t('companyName') }} *</label>
                      <md-input v-model="companyName" type="text"></md-input>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="street-hausnumber md-layout-item md-xsmall-size-100 md-size-100" v-if="!isPrivateRole">
                <div class="md-layout-item md-xsmall-size-100 md-size-50">
                  <ValidationProvider
                    :rules="{
                      required: !isPrivateRole,
                    }"
                  >
                    <md-field class="street">
                      <label>{{ $t('street') }} *</label>
                      <md-input v-model="street" type="text"></md-input>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-xsmall-size-100 md-size-50">
                  <ValidationProvider
                    :rules="{
                      required: !isPrivateRole,
                    }"
                  >
                    <md-field>
                      <label>{{ $t('houseNumber') }} *</label>
                      <md-input v-model="houseNumber" type="text"></md-input>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="city-zip md-layout-item md-xsmall-size-100 md-size-100" v-if="!isPrivateRole">
                <div class="md-layout-item md-xsmall-size-100 md-size-50">
                  <ValidationProvider
                    :rules="{
                      required: !isPrivateRole,
                    }"
                  >
                    <md-field>
                      <label>{{ $t('city') }} *</label>
                      <md-input v-model="city" type="text"></md-input>
                    </md-field>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-xsmall-size-100 md-size-50">
                  <ValidationProvider
                    :rules="{
                      required: !isPrivateRole,
                    }"
                  >
                    <md-field>
                      <label>{{ $t('zipCode') }} *</label>
                      <md-input v-model="zipCode" type="text"></md-input>
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="customer-number md-layout-item md-xsmall-size-100 md-size-100" v-if="!isPrivateRole">
                <md-field>
                  <label>{{ $t('customerNumber') }}</label>
                  <md-input v-model="mdesignCustomerNumber" type="text"></md-input>
                </md-field>
              </div>
            </div>
          </md-card-content>
          <md-card-actions>
            <ValidationProvider :rules="{ required: { allowFalse: false } }" name="checkbox">
              <div class="required-fields">* {{ $t('requiredFields') }}</div>
              <div class="terms-conditions">
                <label class="line">
                  <input v-model="checkbox" type="checkbox" required class="my-checkbox" />
                  <div class="terms-conditions-text">
                    {{ $t('termsAndConditions') }}
                    <a href="mailto:privacy@mdesign.de">privacy@mdesign.de</a>{{ $t('termsAndConditions2')
                    }}<a target="_blank" :href="$t('privacyLink')">{{ $t('here') }}</a>
                  </div>
                </label>
              </div>
            </ValidationProvider>
            <div class="button-container">
              <md-button
                class="md-primary md-round mt-4"
                slot="footer"
                :disabled="invalid"
                :data-bind="recaptchaSubmit"
                @data-callback="submitForm()"
                @click.native="showSwal()"
                >{{ $t('submit') }}</md-button
              >
            </div>
          </md-card-actions>
        </md-card>
      </form>
    </ValidationObserver>
    <input
      type="submit"
      id="capcha"
      class="g-recaptcha"
      slot="footer"
      :data-sitekey="key"
      :data-bind="recaptchaSubmit"
      @data-callback="submitForm()"
    />
  </div>
</template>
<script>
import { extend } from 'vee-validate'
import { required, regex, is } from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import SelectCountry from '@/components/SelectCountry/SelectCountry.vue'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useConfigurationProductLevelStore } from '../../stores/configurationProductLevel'
import { useManagementStore } from '../../stores/management'
import { i18n } from '../../main'

import myMdesignWhiteLogo from '../../../public/img/my_mdesign_white.svg'
import RoloffMatek from '../Products/UserProducts/RoloffMatek.vue'

extend('required', required)
extend('regex', regex)

export default {
  components: {
    SelectCountry,
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      salutation: '',
      boolean: false,
      email: '',
      password: '',
      country: '',
      checkbox: null,
      checkboxStudent: false,
      recaptchaSubmit: false,
      key: '6LcOK9omAAAAAHCSxR1YWtCBSuRTkQ1TDEyWsIab',
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
      loader: false,
      isPrivateRole: true,
      street: '',
      houseNumber: '',
      companyName: '',
      city: '',
      zipCode: '',
      mdesignCustomerNumber: '',
      logo: myMdesignWhiteLogo,
      isRoloffMatek: false,
      isDecker: false,
    }
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()
    return { userStore, companyStore, configurationProductLevelStore, managementStore }
  },
  beforeMount() {
    this.$changePageTitle('Registration')
    this.userStore.createDefaultUserData()
    this.companyStore.resetCompanyStore()
    this.configurationProductLevelStore.resetConfigurationProductLevelStore()
    // this.managementStore.resetManagementStore()
    this.isRoloffMatek = window.location.href.includes('roloff-matek')
    this.isDecker = window.location.href.includes('decker')
  },
  methods: {
    submitForm() {
      var form = document.getElementById('registrationForm')
      if (validate_form(form)) {
        form.submit()
      } else {
        grecaptcha.reset()
      }
      grecaptcha.render('capcha', {
        sitekey: this.key,
        callback: onSubmit,
      })
    },
    async showSwal() {
      const userExistModalTitle = this.$t('userExistModalTitle')
      const userExistModalText = this.$t('userExistModalText')
      const userRegisteredModalTitle = this.$t('registrationMassageTitle')
      const userRegisteredModalText = this.$t('registrationMassageText')
      const errorRegisteredModalTitle = this.$t('error')
      const errorRegisteredModalText = this.$t('errorRegisteredModalText')

      let serverResponse = await this.saveData()
      switch (serverResponse) {
        case true:
          showSuccessSwal()
          break
        case 'userExist':
          showUserExistSwal()
          break
        case false:
          showErrorSwal()
          break
      }
      this.$router.push({ path: '/login' })
      let self = this
      function showUserExistSwal() {
        Swal.fire({
          position: 'middle-end',
          icon: 'error',
          title: userExistModalTitle,
          html: userExistModalText,
          showConfirmButton: true,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: i18n.t('login'),
          cancelButtonText: i18n.t('resetPasswordLink'),
          cancelButtonColor: '#228B22',
        }).then((result) => {
          if (!result.isConfirmed) self.$router.push({ path: '/forgotPassword' })
        })
      }
      function showSuccessSwal() {
        Swal.fire({
          position: 'middle-end',
          icon: 'success',
          title: userRegisteredModalTitle,
          html: userRegisteredModalText,
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#009ee3',
        })
      }
      function showErrorSwal() {
        Swal.fire({
          position: 'middle-end',
          icon: 'error',
          title: errorRegisteredModalTitle,
          html: errorRegisteredModalText,
          showConfirmButton: true,
          allowOutsideClick: false,
          confirmButtonColor: '#009ee3',
        })
      }
    },
    async saveData() {
      if (this.userStore.userData) {
        this.userStore.userData.Email = this.email
        this.userStore.userData.Password = this.password
        this.userStore.userData.Salutation = this.salutation
        this.userStore.userData.LastName = this.lastname
        this.userStore.userData.FirstName = this.firstname
        this.country = this.userStore.userAddress.Country
        this.userStore.userData.Street = this.street
        this.userStore.userData.HouseNumber = this.houseNumber
        this.userStore.userData.City = this.city
        this.userStore.userData.ZipCode = this.zipCode
        this.userStore.userData.CompanyName = this.companyName
        this.userStore.userData.MdesignCustomerNumber = this.mdesignCustomerNumber

        this.loader = true
        const role = this.isPrivateRole
          ? this.checkboxStudent || this.isRoloffMatek || this.isDecker
            ? 'Student'
            : 'User'
          : 'Customer'
        const roleAndID = {
          Student: '{dbf91852-27f1-45cb-81b5-b0203c278530}',
          User: '{639c0e44-6ca8-4356-b266-93ccd308e68e}',
          Customer: '{a8baca23-956d-4202-96fe-9862d737a652}',
        }
        const customerFields = this.isPrivateRole
          ? null
          : {
              CompanyName: this.companyName,
              Street: this.street,
              Housenumber: this.houseNumber,
              City: this.city,
              ZipCode: this.zipCode,
              MdesignCustomerNumber: this.mdesignCustomerNumber,
            }
        const userData = {
          Email: this.email,
          Password: this.password,
          Salutation: this.salutation,
          LastName: this.lastname,
          FirstName: this.firstname,
          Country: this.country,
          RoleID: roleAndID[role],
          isRoloffMatek: this.isRoloffMatek,
          ...customerFields,
        }
        const registrationResult = await this.userStore.registerUser(userData)
        this.loader = false
        return registrationResult
      }
    },
  },
}
</script>
<style scoped scss>
.swal2-styled.swal2-confirm {
  padding: 5px 10px;
  background-color: #009ee3;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.terms-conditions,
.required-fields,
.student-checkbox {
  display: inline-flex;
  color: #aaaaaa;
  margin: 13px 0 0;
}

.line {
  display: inline-flex;
}

.md-theme-default a:not(.md-button) {
  color: var(--md-theme-selection-black-primary);
  text-decoration: underline;
}

.my-checkbox {
  transform: scale(1.5);
  margin-right: 11px;
  display: flex;
  align-self: baseline;
  min-width: 20px;
}

input:checked {
  /* color: var(--md-theme-selection-black-primary); */
  accent-color: var(--md-theme-selection-black-primary);
}

.md-card {
  margin: 0;
}

.md-card .md-card-actions {
  border: none;
}

.input-fields {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .input-fields,
  .md-card-actions,
  .right-side-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .street-hausnumber,
  .city-zip {
    display: flex;
    flex-direction: column;
  }
}

.md-card-actions {
  padding: 15px 20px;
}

.drop-down {
  margin-right: 20px;
}

.password {
  margin-right: 30px;
}

.password-rule {
  color: #280907;
  position: relative;
  font-size: 12px;
  line-height: 18px;
}

#capcha {
  display: none;
}

.private-business-role {
  display: flex;
  padding-left: 35px;
  /* display: flex; */
  align-items: center !important;
  justify-content: start !important;
  min-width: 100px;
}

/deep/.registration-role.md-checked .md-switch-container {
  background-color: #7e8fa7 !important;
}

.street-hausnumber,
.city-zip {
  display: flex;
  padding: 0 !important;
}

.page-content {
  width: 100%;
}

.logo {
  width: 150px !important;
  margin: 0 auto;
  margin-top: 15px;
}

.img-title-registration {
  position: relative;
  top: 60px;
  background: linear-gradient(60deg, #00325b, #009ee3);
  margin: 0 15px;
  height: 80px;
  border-radius: 6px;
  z-index: 2 !important;
}

.img-logo {
  width: 150px;
  margin: 0 auto;
  padding-top: 25px;
  display: block;
}

.registration-title {
  padding-top: 60px;
}
</style>
